import { useFetch } from '../../../Hooks/useFetch';
import Loader from 'Ui/Loader';
import { countriesService } from '../../../Services/Services';
import { CountryInterface } from '../../../interfaces/Country';
interface Country {
    code: string;
    name: string;
    population: number;
}

export default function Country(props: { countryCode: string }) {
    const { data: country, isLoading } = useFetch<CountryInterface, [string]>({
        service: async () => {
            const data = await new countriesService().getCountryByCode(props.countryCode);
            return {
                ...data,
                name: data.name ?? 'Unknown',
                population: data.population ?? 0,
            };
        },
        cacheKey: `country_${props.countryCode}`,
        dependencies: [props.countryCode],
    });

    if (isLoading) {
        return <Loader />;
    }

    if (!country) {
        return <></>;
    }

    return (
        <div
            key={country.code}
            className={'column is-half-tablet is-one-third-desktop'}
        >
            {country.name}
        </div>
    );
}
