import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import buttonfilter from '@assets/icons/buttonfilter.svg';
import buttonbackfilter from '@assets/icons/backbuttonfilter.svg';
import iconsetting from '@assets/icons/iconsetting.svg';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-self: baseline;
    padding: 0 20px 0px 15px;
`;

export const FilterTitle = styled.div`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size6};
    font-weight: 700;
`;

export const FilterTag = styled.div`
    display: flex;
    padding: 5px ${props => props.theme.globalSize.size6};
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 6px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size7};
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.07px;
    opacity: 1;
    visibility: visible;
    transition:
        opacity 0.5s ease-in-out,
        visibility 0s linear 0.5s;

    &.hidden {
        opacity: 0;
        visibility: hidden;
        transition:
            opacity 0.5s ease-in-out,
            visibility 0s linear 0.5s;
    }
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize6};
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.005em;
    }
`;

export const IconWrapper = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const CircleIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primaryLighter};
    width: ${props => props.theme.globalSize.size6};
    height: ${props => props.theme.globalSize.size6};
`;

export const TimesIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.white};
    font-size: 0.8em;
    position: absolute;
`;

export const FiltersContainer = styled.div`
    display: inline-flex;
    overflow-x: auto;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    cursor: pointer;
    white-space: nowrap;
    scrollbar-width: none;

    opacity: 1;
    visibility: visible;
    transition:
        opacity 0.5s ease-in-out,
        visibility 0s linear 0.5s;

    &.hidden {
        opacity: 0;
        visibility: hidden;
        transition:
            opacity 0.5s ease-in-out,
            visibility 0s linear 0.5s;
    }
`;

export const ResetButton = styled.div`
    color: ${props => props.theme.colors.greyDark};
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.mobileSize6};
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.06px;
    text-align: center;
    &:hover {
        text-decoration: underline;
    }
`;

interface RightSidebarProps {
    'data-showfilters'?: boolean;
}
export const RightSidebar = styled.div<RightSidebarProps>`
    background: ${props => props.theme.colors.white};
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    z-index: 501;
    bottom: 0;
    width: 312px;
    box-shadow: ${props => (props['data-showfilters'] ? '0px 4px 14px 0px rgba(87, 87, 86, 0.35) ' : 'unset')};
    flex-direction: column;
    align-items: center;
    margin-top: 25%;
    transform: ${props => (props['data-showfilters'] ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.5s ease;
`;

export const ButtonFilter = styled.span`
    background-image: url(${buttonfilter});
    background-size: cover;
    width: 43px;
    height: 32px;
    align-self: flex-end;
    margin-right: 5%;
    cursor: pointer;
    &:active {
        background: #290180;
    }
`;

export const ButtonBackFilter = styled.span`
    background-image: url(${buttonbackfilter});
    background-size: cover;
    width: 22.28px;
    height: 19px;
    align-self: flex-end;
    margin-bottom: 4px;
    cursor: pointer;
`;

export const IconSetting = styled.span`
    background-image: url(${iconsetting});
    background-size: cover;
    width: 19px;
    height: 19px;
`;

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: 20px;
    font-weight: 700;
    line-height: 26.04px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const SideBarTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20%;
    gap: 118px;
`;
export const SideBarHeaderContainer = styled.div`
    width: 74%;
`;

interface BarHrProps {
    isFirst?: boolean;
    $margintop?: number;
    $marginbottom?: number;
}

const StyledBarHr = styled.hr<{ width: string; $margintop: number; $marginbottom: number }>`
    background-color: ${props => props.theme.colors.secondary};
    width: ${props => props.width};
    height: 1px;
    margin-top: ${props => props.$margintop}%;
    margin-bottom: ${props => props.$marginbottom}px;
`;

export const BarHr: React.FC<BarHrProps> = ({ isFirst, $margintop = 10, $marginbottom = 23 }) => (
    <StyledBarHr
        width={isFirst ? '100%' : '74%'}
        $margintop={$margintop}
        $marginbottom={isFirst ? 50 : $marginbottom}
    />
);

export const SelectDropDownStyle = styled.div`
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
    &.closed .dropdown-content {
        display: none;
    }
    &.open .dropdown-content {
        display: block;
    }
`;

export const ButtonSeeAll = styled.button`
    width: max-content;
    height: 36px;
    padding: 8px 16px 8px 16px;
    gap: 0px;
    border-radius: 6px;
    opacity: 0px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    border: unset;
`;

export const DeleteAll = styled.button`
    width: max-content;
    height: 36px;
    padding: 8px 16px 8px 16px;
    gap: 0px;
    border-radius: 6px;
    border: 1px;
    opacity: 0px;
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.primary};
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 3vw;
    top: 10%;
    position: relative;
`;

export const SectionFilter = styled.div`
    &.section-filter-hide-dropdown {
        .dropdown.is-active .dropdown-menu,
        .dropdown.is-hoverable:hover .dropdown-menu {
            display: none !important;
        }
    }
    .icon {
        transition: transform 0.3s ease;
    }

    &.icon-rotated .icon {
        transform: rotate(0deg) !important;
    }

    &.icon-not-rotated .icon {
        transform: rotate(180deg) !important;
    }
`;

export const SectionFilterContainer = styled.div`
    width: inherit;
    display: flex;
    text-align-last: center;
    justify-content: space-around;
    ${props => props.theme.devices.mobile} {
        width: inherit;
        display: flex;
        text-align-last: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
`;
export const FilterComponent = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
`;

interface FilterMenuProps {
    $isOpen?: boolean;
}

export const Overlay = styled.div<FilterMenuProps>`
    ${props =>
        props.$isOpen &&
        `
        content: '';
        position: fixed;  
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props.theme.colors.greyDark};
        opacity: ${props.theme.colors.opacityMedium};
        z-index: 500;   `}
`;
