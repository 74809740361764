import { useEffect, useState } from 'react';
import { ProductInterface } from '../interfaces/Product.ts';
import { ObjectItem } from '../interfaces/Filters.ts';

function useProductFilters(
    products: ProductInterface[] | undefined,
    newProducts: ProductInterface[] | undefined,
    filters: ObjectItem[] | undefined,
    code: string[],
    filterObjects: { name: string; value: string }[],
    filterItemsConditionObject: { name: string; value: string }[],
    filterItemsGeographical: { name: string; value: string }[],
) {
    const [physicalProducts, setPhysicalProducts] = useState<ProductInterface[] | undefined>(products);

    useEffect(() => {
        const filterProducts = () => {
            const isPhysical = filterObjects.length > 0 && code.includes(filterObjects[0]?.value);
            const isVirtuel = filterObjects.length > 1 && code.includes(filterObjects[1]?.value);
            const isSecable = filterItemsConditionObject.length > 0 && code.includes(filterItemsConditionObject[0]?.value);
            const isDivisible = filterItemsConditionObject.length > 1 && code.includes(filterItemsConditionObject[1]?.value);

            const hasGeographicalFilter = filterItemsGeographical.some(geoFilter => code.includes(geoFilter.value));

            if (!isPhysical && !isVirtuel && !isSecable && !isDivisible && !hasGeographicalFilter) {
                return [];
            }

            return (newProducts && newProducts?.length > 0 ? newProducts : products)?.filter(product => {
                const productType = product.productType;
                const productDivisibleChoice = product.productDivisibleChoice;

                let matchesType = true;
                if (isPhysical || isVirtuel) {
                    if (!productType || !productType.name) return false;
                    const filter = filters?.find(filter => filter.name === productType.name);
                    matchesType = (isPhysical && filter?.physical) || (isVirtuel && !filter?.physical);
                }

                const matchesSecable = isSecable ? product.cumulative === true : true;
                const matchesDivisible = isDivisible ? productDivisibleChoice?.isDivisible === true : true;

                const matchesGeographical = hasGeographicalFilter ? product?.availableCountries?.some(country => code.includes(country)) : true;

                return matchesType && matchesSecable && matchesDivisible && matchesGeographical;
            });
        };

        setPhysicalProducts(filterProducts() || []);
    }, [newProducts, code, filterObjects, filterItemsConditionObject]);

    return { physicalProducts };
}

export default useProductFilters;
