import classNames from 'classnames';
import Country from '../Country.tsx';

type CountryProps = {
    countries: string[] | null | undefined;
    isHidden: boolean;
};

export default function CountryTab({ countries, isHidden }: CountryProps) {
    if (!countries) {
        return <></>;
    }

    return (
        <>
            <div className={classNames('tab-content product-information', { 'is-hidden': isHidden })}>
                <div
                    id={'tab-menu-content tab-content-countries'}
                    className={'columns is-multiline'}
                >
                    {countries &&
                        countries.map((country: string) => (
                            <Country
                                countryCode={country}
                                key={country}
                            />
                        ))}
                </div>
            </div>
        </>
    );
}
