import { useLocation, useParams } from 'react-router-dom';
import Image from '../Image';
import { useFetch } from '../../../Hooks/useFetch';
import VariantSelector from '../VariantSelector';
import { Suspense, useState } from 'react';
import { ProductVariantInterface } from '../../../interfaces/Productvariant';
import { isEmpty } from '../../../Utils/Object';
import deliveryImageUrl from '@assets/icons/delivery.svg';
import ProductInformation from './ProductInformation';
import ProductInformationMobile from './Mobile/ProductInformationMobile';
import ProductType from './ProductType';
import Flags from '../Flags';
import Loader from 'Ui/Loader';
import { formatCurrency } from '../../../Utils/numberFormat';
import { cartAddToCart, uiBreadcrumbs, uiDeviceDetect, uiQuantityInput } from '../../../Utils/LoadModule';
import { ErrorBoundary } from 'react-error-boundary';
import { ProductLayoutStyled } from '../../Styled/ProductLayoutStyled';
import styled from 'styled-components';
import { FlagsStyled } from '../../Styled/FlagsStyled';
import { BreadcrumbsContainer } from './productsStyle';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { ProductRoutePrefixProvider, useProductRoutePrefix } from '../../../ProductRoutePrefixContext';
import { useBasePrefix } from '../../../BasePrefixContext';
import { ProductInterface } from '../../../interfaces/Product';
import { productsService, productVariantsService } from '../../../Services/Services';

const Breadcrumbs = uiBreadcrumbs();

const [AddToCart, useDeviceDetect, QuantityInput] = await Promise.all([cartAddToCart(), uiDeviceDetect(), uiQuantityInput()]);

const ProductInformationContainerStyled = styled.div`
    .type-flags-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 24px;
    }

    .delivery-time {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 33px 0px 33px 0px;

        > img {
            width: 30px;
            height: 25px;
            fill: ${props => props.theme.colors.greyDark};
        }

        > span {
            color: ${props => props.theme.colors.greyDark};
            font-family: ${props => props.theme.fonts.familyPrimary};
            font-size: ${props => props.theme.globalSize.size6};
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
    }

    .product-shop-type {
        margin-right: 20px;
        gap: 15px;
        padding-top: 27px;
        flex-wrap: wrap;
    }
`;

const Title = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size2};
    font-weight: 700;
    line-height: 36.46px;
    text-align: left;
    color: ${props => props.theme.colors.primary};
`;

export default function ProductDetail() {
    const { productCode } = useParams();
    const { data: productData, isLoading } = useFetch<{ product: ProductInterface; variants: ProductVariantInterface[] }, [string | undefined]>({
        service: async () => {
            const productService = new productsService();
            const product = await productService.getVariantsProductByCode(productCode || '');
            const variants = await new productVariantsService().getAllProductVariantsByProduct(product.id);
            return { product, variants };
        },
        cacheKey: `product_with_variants_${productCode}`,
        dependencies: [productCode],
    });

    const [variantSelected, setVariantSelected] = useState({} as ProductVariantInterface);
    const { isMobile } = useDeviceDetect();
    const [quantityToAdd, setQuantityToAdd] = useState(1);
    const { t } = useSharedTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const priceStr = queryParams.get('price');
    const price = priceStr ? parseFloat(priceStr) : null;
    const prefix = useProductRoutePrefix();
    const basePrefix = useBasePrefix();

    if (isLoading || !productData) {
        return <Loader />;
    }

    const { product, variants } = productData;

    const displayDeliveryTime = (variant: ProductVariantInterface) => {
        if (variant.minDeliveryTime + variant.maxDeliveryTime === 0) {
            return <span>Immédiat</span>;
        } else if (variant.minDeliveryTime === variant.maxDeliveryTime) {
            return <span>{`${variant.minDeliveryTime} jours ouvrés`}</span>;
        } else {
            return <span>{`${variant.minDeliveryTime}/${variant.maxDeliveryTime} jours ouvrés`}</span>;
        }
    };
    const breadcrumbItems = [
        { name: t('base.avantage.breadcrumbs.home'), url: `${basePrefix}` },
        { name: t('base.footer.quickAccess.link.boutique'), url: `${basePrefix}${prefix}/shop` },
        { name: product.name, url: '/' + product.name },
    ];
    const mobileBreadcrumbs = isMobile ? [breadcrumbItems[breadcrumbItems.length - 1]] : breadcrumbItems;

    return (
        <>
            <Suspense fallback={<Loader />}>
                <ProductRoutePrefixProvider>
                    <BreadcrumbsContainer className='is-flex is-justify-content-start pl-4'>
                        <Breadcrumbs
                            items={mobileBreadcrumbs}
                            showEllipsis={false}
                        ></Breadcrumbs>
                    </BreadcrumbsContainer>
                </ProductRoutePrefixProvider>
                <ProductLayoutStyled className={'products-layout container is-fluid'}>
                    <div className={'container'}>
                        <div className={'columns'}>
                            <div className={'column has-text-centered'}>
                                <Image
                                    product={product}
                                    type={'sylius_large'}
                                    classNames={'img-product-details'}
                                />
                            </div>
                            <ProductInformationContainerStyled
                                className={'column'}
                                id={'product-information-container'}
                            >
                                <Title>{product.name}</Title>
                                <div className={'type-flags-container'}>
                                    <div className={'product-shop-type is-flex'}>
                                        <ProductType product={product} />
                                    </div>
                                    <FlagsStyled className='flags'>
                                        <Flags product={product} />
                                    </FlagsStyled>
                                </div>
                                {!isEmpty(variantSelected) && (
                                    <div className='delivery-time'>
                                        <img
                                            src={deliveryImageUrl}
                                            alt='delivery-time'
                                        />
                                        {displayDeliveryTime(variantSelected)}
                                    </div>
                                )}
                                <div className='product-variant'>
                                    {
                                        <VariantSelector
                                            variants={variants}
                                            variantSelected={variantSelected}
                                            setVariantSelected={setVariantSelected}
                                            price={price}
                                        />
                                    }
                                </div>
                                <div id='product-add-to-cart-container'>
                                    <div className='columns'>
                                        <div className='column is-one-third'>
                                            <Suspense fallback={<Loader />}>
                                                <QuantityInput
                                                    quantity={quantityToAdd}
                                                    setQuantity={setQuantityToAdd}
                                                    min={1}
                                                    max={100}
                                                ></QuantityInput>
                                            </Suspense>
                                        </div>
                                        <div className='column'>
                                            <Suspense fallback={<Loader />}>
                                                <ErrorBoundary
                                                    fallback={<span className={'is-size-7 has-text-weight-light'}>Pannier indisponible</span>}
                                                >
                                                    <AddToCart
                                                        productVariantCode={isEmpty(variantSelected) ? '' : variantSelected.code}
                                                        quantity={quantityToAdd}
                                                        extraLabel={
                                                            isEmpty(variantSelected) ? '' : (
                                                                formatCurrency(quantityToAdd * variantSelected.originalPrice)
                                                            )
                                                        }
                                                        disabled={isEmpty(variantSelected)}
                                                        // onSuccess={() => alert('Added to cart')}
                                                        onFaillure={(message: string) => {
                                                            alert(message);
                                                        }}
                                                    />
                                                </ErrorBoundary>
                                            </Suspense>
                                        </div>
                                    </div>
                                </div>
                            </ProductInformationContainerStyled>
                        </div>
                        <Suspense fallback={<Loader />}>
                            {isMobile ?
                                <ProductInformationMobile product={product} />
                            :   <ProductInformation product={product} />}
                        </Suspense>
                    </div>
                </ProductLayoutStyled>
            </Suspense>
        </>
    );
}
