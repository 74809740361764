import styled from 'styled-components';

export const FlagsStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    img,
    .drom {
        width: 36px !important;
        height: 27px !important;
        border-radius: 2px;
        box-shadow: 0 4px 4px 0 rgba(87, 87, 86, 0.15);
        border-radius: 4px;

        ${props => props.theme.devices.mobile} {
            box-shadow: none;
            width: 24px !important;
            height: 18px !important;
        }
    }

    .drom {
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: 7px !important;
        font-weight: 700;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border: 1px solid #c0c0c024;

        ${props => props.theme.devices.mobile} {
            box-shadow: 0 4px 14px 0 rgba(87, 87, 86, 0.35);
        }

        &::before {
            content: 'DROM';
        }
    }
`;
