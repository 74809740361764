import styled from 'styled-components';

export const ProductsListLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
    padding: 5% 0 5% 0;
    width: 100%;
    ${props => props.theme.devices.mobile} {
        padding: 30% 0 20% 0;
    }
    .loader {
        margin: auto;
    }

    .paging {
        margin: auto;
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.07px;
        color: ${props => props.theme.colors.greyDark};
        cursor: pointer;
        justify-content: center;
        text-align: center;
        display: flex;
        padding: 12px 10px;
        margin: 20px;
    }

    #itemsPerPage {
        border: 1px solid ${props => props.theme.colors.greyDark};
        border-radius: 10px;
        width: 15rem;
        margin: 30px 17%;
    }

    #allItems {
        text-decoration: underline;
        letter-spacing: 0.06px;
        margin: 5px;
        font-size: 12px;
        font-weight: 400;
    }
`;
interface ProductLayoutStyledProps {
    'data-padding'?: boolean;
}
export const ProductLayoutStyled = styled.div<ProductLayoutStyledProps>`
    padding: ${props => (props['data-padding'] ? '0' : '10% 10px')}!important;

    display: flex;
    flex-direction: row;

    .img-product-details {
        width: 408px;
        height: 265.79px;
        border-radius: 12px;
        box-shadow: 0px 6px 17px 0px #4d4d4d47;
    }

    .products-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .grid-4-columns {
        grid-template-columns: repeat(4, 1fr);
        ${props => props.theme.devices.mobile} {
            grid-template-columns: repeat(1, 1fr);
        }
        display: grid;
        flex-wrap: wrap;
        width: 97%;
        justify-content: center;
        > .product:hover {
            .product-name {
                font-weight: bold;
                top: 40px;
            }
            .product-price {
                opacity: 1;
                top: 97px;
            }
            .product-card {
                background-color: #f5f5f5;
                > .product-card-body {
                    > .product-info {
                        > .product-type {
                            > span {
                                background-color: white;
                            }
                        }
                    }
                    .product-image {
                        transform: rotate(-10deg) scale(1.1);
                    }
                }
            }
        }
    }

    .grid-1-column {
        grid-template-columns: repeat(1, 1fr);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        .product-default {
            .product {
                .product-price {
                    white-space: nowrap;
                    scroll-behavior: smooth;
                    width: 100% !important;
                    position: absolute;
                    opacity: 0;
                    transition:
                        opacity 0.9s ease,
                        top 0.6s ease;
                    z-index: 1;
                    top: 120px;
                    > .arrow-container {
                        width: 100%;
                        display: flex;
                        bottom: 35px !important;
                        position: relative;
                        justify-content: space-between;
                        z-index: 20;
                        pointer-events: none;
                        > .scroll-left-arrow,
                        .scroll-right-arrow {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            background-color: white;
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            pointer-events: auto;
                        }
                        > .scroll-left-arrow {
                            > .arrowLeft {
                                border: solid ${props => props.theme.colors.primary};
                                border-width: 0 2px 2px 0;
                                display: inline-block;
                                padding: 3px;
                                transform: rotate(135deg);
                            }
                        }
                        > .scroll-right-arrow {
                            > .arrowRight {
                                border: solid ${props => props.theme.colors.primary};
                                border-width: 0 2px 2px 0;
                                display: inline-block;
                                padding: 3px;
                                transform: rotate(-45deg);
                            }
                        }
                    }
                    > .price-map-container::-webkit-scrollbar {
                        display: none;
                    }
                    > .price-map-container {
                        display: flex;
                        overflow-x: auto;
                        flex-direction: row;
                        justify-content: start;
                        scroll-behavior: smooth;
                        max-width: 100%;
                        > div {
                            box-shadow: 0px 1px 2px 1px rgba(77, 77, 77, 0.2);
                            padding: 2px 3px;
                            margin: 5px;

                            border-radius: 4px;
                            display: inline-block;
                            text-decoration: none;
                            background-color: #f5f5f5;
                            padding: 6px;
                            span {
                                > a {
                                    color: ${props => props.theme.colors.greyDark};
                                    text-align: center;
                                    font-family: ${props => props.theme.fonts.familyPrimary};
                                    font-size: ${props => props.theme.globalSize.size5};
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 150%;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-middle-highlight {
            .product {
                .product-name {
                    font-weight: bold;
                    top: 40px;
                }
                .product-price {
                    opacity: 1;
                    top: 100px;
                    width: 100% !important;
                    > .arrow-container {
                        bottom: 35px !important;
                        pointer-events: none;
                    }
                }
                .product-card {
                    background-color: #f5f5f5;

                    > .product-card-body {
                        > .product-info {
                            > .product-type {
                                > span {
                                    background-color: white;
                                }
                            }
                        }
                    }
                    .product-image {
                        transform: rotate(-10deg) scale(1.1);
                    }
                }
            }
        }
    }

    .grid-1-column,
    .grid-4-columns,
    .product-slider {
        .product {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            height: 32rem;
            padding: 0 10px;
            padding-bottom: 25px;
            overflow: hidden;
            position: relative;
            transition: all 0.5s ease;

            .product-name {
                position: absolute;
                top: 100px;
                transition:
                    top 0.9s ease,
                    opacity 0.6s ease;
                z-index: 2;
                align-items: center;
                overflow: visible;
                height: 55px;
                display: flex;
                align-items: center;
                > span {
                    color: ${props => props.theme.colors.greyDark};
                    text-align: center;
                    font-size: ${props => props.theme.globalSize.size4};
                    text-transform: uppercase;
                    display: inline-block;
                }
            }
            .product-price {
                align-items: center;
                align-self: center;
                white-space: nowrap;
                scroll-behavior: smooth;
                max-width: 80%;
                position: absolute;
                opacity: 0;
                transition:
                    opacity 0.9s ease,
                    top 0.6s ease;
                z-index: 1;
                top: 120px;
                > .arrow-container {
                    width: 100%;
                    bottom: 35px !important;
                    display: flex;
                    position: relative;
                    justify-content: space-between;
                    z-index: 20;
                    pointer-events: none;

                    > .scroll-left-arrow,
                    .scroll-right-arrow {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        background-color: white;
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        pointer-events: auto;
                    }
                    > .scroll-left-arrow {
                        > .arrowLeft {
                            border: solid ${props => props.theme.colors.primary};
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(135deg);
                        }
                    }
                    > .scroll-right-arrow {
                        > .arrowRight {
                            border: solid ${props => props.theme.colors.primary};
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(-45deg);
                        }
                    }
                }
                > .price-map-container::-webkit-scrollbar {
                    display: none;
                }
                > .price-map-container {
                    display: flex;
                    overflow-x: auto;
                    flex-direction: row;
                    justify-content: stretch;
                    z-index: 1;
                    > div {
                        box-shadow: 0px 1px 2px 1px rgba(77, 77, 77, 0.2);
                        padding: 2px 3px;
                        margin: 5px;

                        border-radius: 4px;
                        display: inline-block;
                        text-decoration: none;
                        background-color: #f5f5f5;
                        padding: 6px;
                        span {
                            > a {
                                color: ${props => props.theme.colors.greyDark};
                                text-align: center;
                                font-family: ${props => props.theme.fonts.familyPrimary};
                                font-size: ${props => props.theme.globalSize.size5};
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%;
                                text-decoration: none;
                            }
                        }
                    }
                    > div:hover {
                        background-color: ${props => props.theme.colors.primary};
                        > span {
                            > a {
                                color: white;
                            }
                        }
                    }
                }
            }

            .product-card {
                border-radius: 7.5px;
                background-color: ${props => props.theme.colors.white};
                box-shadow: 0px 6px 17px 0px rgba(77, 77, 77, 0.3);
                height: 330px;
                max-width: 100%;
                padding: 35px 70px 68px 70px;
                transition-duration: 1s;
                transition-property: background-color;

                .product-card-body {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    max-width: 100%;
                    margin: auto;
                    gap: 40px;

                    .product-info {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;

                        .countries {
                            display: flex;
                            height: 29px;
                            justify-content: center;
                            align-items: center;
                            .flags {
                                > .span {
                                    border-radius: 4px;
                                }
                            }
                        }

                        .product-type {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;

                            > span {
                                color: ${props => props.theme.colors.greyDark};
                                font-family: ${props => props.theme.fonts.familyPrimary};
                                font-size: ${props => props.theme.globalSize.size6};
                                line-height: 140%;
                                font-weight: 500;
                                padding: 18px 16px;
                                border-radius: 6px;
                                letter-spacing: 0.07px;
                                background-color: #f5f5f5;
                                transition-duration: 1s;
                                transition-property: background-color;
                            }
                        }
                    }

                    .product-image {
                        display: flex;
                        width: 190px;
                        height: 124px;
                        justify-content: center;
                        align-items: center;
                        transition-duration: 1s;
                        transition-property: transform;

                        > img {
                            height: 100%;
                            width: 100%;
                            margin: auto;
                            border-radius: 15px;
                            box-shadow: 0 6px 17px 0 rgba(77, 77, 77, 0.28);
                        }
                    }
                }
            }

            .product-link {
                position: absolute;
                height: 65%;
                width: 100%;
                align-self: center;
                z-index: 1;
            }
        }
    }

    .grid-2-columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        margin: 20px 0px;
        justify-items: center;

        .product-default {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .product-middle-highlight {
            display: flex;
            justify-content: center;
            width: 100%;
            .product {
                .product-name {
                    top: 40px;
                    > span {
                        font-weight: bold;
                    }
                }
                .product-price {
                    opacity: 1;
                    top: 85px;
                }
                .product-card {
                    background-color: #f5f5f5;
                    > .product-card-body {
                        > .product-info {
                            > .product-type {
                                > span {
                                    background-color: white;
                                }
                            }
                        }
                    }
                    .product-image {
                        transform: rotate(-10deg) scale(1.1);
                    }
                }
            }
        }

        .product {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            width: 90%;
            position: relative;
            margin-bottom: ${props => props.theme.globalSize.size4};
            height: 20rem;
            padding-top: 50px;

            .product-name {
                position: absolute;
                top: 90px;
                transition:
                    top 0.9s ease,
                    opacity 0.6s ease;
                z-index: 2;
                align-items: center;
                height: 40px;
                display: flex;
                align-items: center;
                > span {
                    color: ${props => props.theme.colors.greyDark};
                    text-align: center;
                    font-size: ${props => props.theme.globalSize.size4};
                    text-transform: uppercase;
                    display: inline-block;
                }
            }

            .product-price {
                white-space: nowrap;
                scroll-behavior: smooth;
                width: 90%;
                position: absolute;
                opacity: 0;
                transition:
                    opacity 0.9s ease,
                    top 0.6s ease;
                z-index: 1;
                top: 120px;
                > .arrow-container {
                    width: 100%;
                    display: flex;
                    bottom: 35px;
                    position: relative;
                    justify-content: space-between;
                    z-index: 20;
                    pointer-events: none;

                    > .scroll-left-arrow,
                    .scroll-right-arrow {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        background-color: white;
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        pointer-events: auto;
                    }
                    > .scroll-left-arrow {
                        > .arrowLeft {
                            border: solid ${props => props.theme.colors.primary};
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(135deg);
                        }
                    }
                    > .scroll-right-arrow {
                        > .arrowRight {
                            border: solid ${props => props.theme.colors.primary};
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(-45deg);
                        }
                    }
                }
                > .price-map-container::-webkit-scrollbar {
                    display: none;
                }
                > .price-map-container {
                    display: flex;
                    overflow-x: auto;
                    flex-direction: row;
                    justify-content: stretch;
                    z-index: 1;
                    > div {
                        box-shadow: 0px 1px 2px 1px rgba(77, 77, 77, 0.2);
                        padding: 2px 3px;
                        margin: 5px;

                        border-radius: 4px;
                        display: inline-block;
                        text-decoration: none;
                        background-color: #f5f5f5;
                        padding: 6px;
                        span {
                            > a {
                                color: ${props => props.theme.colors.greyDark};
                                text-align: center;
                                font-family: ${props => props.theme.fonts.familyPrimary};
                                font-size: ${props => props.theme.globalSize.size5};
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            .product-card {
                position: absolute;
                display: flex;
                justify-content: center;
                height: 185px;
                width: 175px !important;
                padding: 8px 16px;
                background-color: purple;
                border-radius: 7.5px;
                background-color: ${props => props.theme.colors.white};
                box-shadow: 0px 6px 17px 0px rgba(77, 77, 77, 0.3);

                .product-card-body {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 10px;
                    gap: 15px;

                    .product-info {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;

                        .countries {
                            display: flex;
                            height: ${props => props.theme.globalSize.size4};
                            justify-content: center;
                            align-items: center;

                            .flags {
                                gap: 15px;
                                ${props => props.theme.devices.desktop} {
                                    gap: ${props => props.theme.globalSize.size4};
                                }

                                > span {
                                    border-radius: 4px;
                                    height: ${props => props.theme.globalSize.size4};
                                    width: ${props => props.theme.globalSize.size2};
                                }
                            }
                        }

                        .product-type {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;

                            > span {
                                color: ${props => props.theme.colors.greyDark};
                                font-family: ${props => props.theme.fonts.familyPrimary};
                                font-size: ${props => props.theme.globalSize.size6};
                                line-height: 140%;
                                font-weight: 500;
                                padding: 0 16px;
                                border-radius: 6px;
                                letter-spacing: 0.07px;
                                background-color: #f5f5f5;
                                transition-duration: 1s;
                                transition-property: background-color;
                            }
                        }
                    }

                    .product-image {
                        display: flex;
                        width: 112px;
                        height: 70px;
                        padding-right: 0;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;

                        > img {
                            width: 112px;
                            height: 70px;
                            border-radius: 15px;
                            box-shadow: 0 6px 17px 0 rgba(77, 77, 77, 0.28);
                        }
                    }
                }
            }

            .product-link {
                position: absolute;
                height: 50%;
                width: 100%;
                align-self: center;
                z-index: 1;
            }
        }
    }

    .mobile-view-modes {
        display: flex;
        justify-content: center;
        position: absolute;
        right: 42%;
        z-index: 20;

        .mobile-view-mode-tabs {
            display: flex;
            cursor: pointer;
            border-bottom: 1px solid ${props => props.theme.colors.greyLight};

            #mobile-view-mode-1,
            #mobile-view-mode-2 {
                display: flex;
                padding: 6px 12px;
                justify-content: center;
                align-items: flex-start;
                gap: 6px;
                align-self: stretch;

                > span {
                    font-family: ${props => props.theme.fonts.familyPrimary};
                    font-size: ${props => props.theme.globalSize.size6};
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
            }

            .mobile-view-mode-inactive {
                color: ${props => props.theme.colors.greyDark};
            }

            .mobile-view-mode-active {
                border-bottom: 1px solid ${props => props.theme.colors.primary};
                color: ${props => props.theme.colors.primary};
            }
        }
    }

    #product-add-to-cart-container {
        margin-top: ${props => props.theme.globalSize.size4};
        width: 100%;

        button {
            height: 40px;
            border: 1px solid ${props => props.theme.colors.primary};
        }
    }
`;

export const TitleSection = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size2};
    font-weight: 700;
    margin-top: 60px;
    line-height: 36.46px;
    text-align: left;
    color: ${props => props.theme.colors.primary};
    text-transform: uppercase;
`;
