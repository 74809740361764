import classNames from 'classnames';
import { ProductAttributeInterface } from '../../../../interfaces/ProductAttribute.ts';

type AttributeProps = {
    productAttributes: ProductAttributeInterface[];
    isHidden: boolean;
};

export default function AttributeTab({ productAttributes, isHidden }: AttributeProps) {
    return (
        <>
            <div className={classNames('tab-menu-content tab-content product-information', { 'is-hidden': isHidden })}>
                {productAttributes &&
                    productAttributes.map((productAttribute: ProductAttributeInterface) => (
                        <div key={productAttribute.id}>
                            <span className={'product-details-name'}>{productAttribute.name} : </span>
                            <span className={'product-details-value'}>{String(productAttribute.value)}</span>
                        </div>
                    ))}
            </div>
        </>
    );
}
