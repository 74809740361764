import styled from 'styled-components';

export const ProductTabsContainerStyled = styled.div`
    max-width: 1201px;
    margin: auto;
    font-weight: 700;
    .tab-menu-container {
        font-size: ${props => props.theme.globalSize.size3};
    }
    .product-information {
        font-size: ${props => props.theme.globalSize.size4};
        padding: 70px 152px;
        min-height: 340px;

        div.product-details {
            margin-bottom: 25px;
        }

        .product-details-name {
            font-weight: 700;
            font-size: ${props => props.theme.globalSize.size6};

            text-transform: uppercase;
        }

        .product-details-value {
            text-transform: lowercase;
            font-weight: 400;
            font-size: ${props => props.theme.globalSize.size6};
        }

        .product-description {
            font-size: ${props => props.theme.globalSize.size6};

            strong,
            a {
                color: ${props => props.theme.colors.greyDark};
            }

            a {
                text-decoration: underline;
            }

            ul,
            ol {
                list-style-type: revert;
                list-style-position: inside;
            }
        }
    }
`;

export const TabsProductStyled = styled.div`
    font-size: ${props => props.theme.globalSize.size4};
    margin-bottom: 0;

    > ul > li {
        &.is-active .tab-menu-container a {
            border-bottom-color: ${props => props.theme.colors.primary};
            color: ${props => props.theme.colors.primary};
            ${props => props.theme.devices.mobile} {
                border-bottom-color: ${props => props.theme.colors.greyDark};
                color: ${props => props.theme.colors.greyDark};
            }
        }
    }

    ${props => props.theme.devices.mobile} {
        > ul {
            flex-direction: column;
            width: 100%;
            border-bottom: none;

            > li {
                width: 100%;
                padding: 30px 0;
                border-top: 1px solid ${props => props.theme.colors.secondary};
                z-index: 10;

                &.is-deploy {
                    .tab-menu-container {
                        max-height: 10000px;
                        overflow-y: hidden;

                        .tab-menu-content {
                            opacity: 1;
                        }
                    }
                }

                &:last-child {
                    border-bottom: 1px solid ${props => props.theme.colors.secondary};
                }

                .tab-menu-container {
                    border: 1px solid #f5f5f5;
                    border-radius: 4px;
                    background-color: white;
                    transition: max-height 1s;
                    max-height: 46px;

                    .product-details-name {
                        text-transform: uppercase;
                    }

                    .product-details-value {
                        text-transform: lowercase;
                        text-wrap: initial;
                    }

                    .tab-menu-content {
                        font-size: ${props => props.theme.globalSize.size6};
                        font-weight: 400;
                        padding: 0 12px;
                        opacity: 0;
                        transition: opacity 0.5s;

                        > div {
                            margin-bottom: 10px;

                            &:first-child {
                                margin-top: 20px;
                            }

                            &:last-child {
                                margin-bottom: 32px;
                            }
                        }

                        .product-description {
                            a {
                                display: block;
                                text-transform: inherit;
                                text-decoration: underline;
                                font-weight: inherit;
                                padding: 0;
                                &:after {
                                    content: none;
                                }
                            }

                            p {
                                text-wrap: initial;
                            }

                            ul,
                            ol {
                                list-style-position: inside;
                                list-style-type: revert;
                                display: block;
                                border: none;

                                li {
                                    display: revert;
                                }
                            }

                            strong {
                                font-weight: normal;
                                color: ${props => props.theme.colors.greyDark};
                            }
                        }
                    }
                }

                a {
                    font-family: ${props => props.theme.fonts.familySecondary}, sans-serif;
                    font-size: ${props => props.theme.globalSize.size6};
                    font-weight: 700;
                    display: flex;
                    justify-content: space-between;
                    padding: 8px 12px;
                    text-transform: uppercase;
                    color: ${props => props.theme.colors.greyDark};
                    border: none;
                }
            }
        }

        #tab-content-countries {
            .column {
                padding: 0 12px 10px 12px;
            }
        }
    }
`;
