import fidelityAdvantageImageUrl from '@assets/icons/fidelity_advantage.png';
import { formatDecimal } from '../../Utils/numberFormat.ts';

type FidelityAdvantageProps = {
    fidelityAdvantage: number;
};

// TODO : Maybe in Emrys Base ?
// ui exist will smaller size : <AdvantageAmount position={"before"} value={formatDecimal(200)}/>

export default function FidelityAdvantage({ fidelityAdvantage }: FidelityAdvantageProps) {
    return (
        <>
            <img
                src={fidelityAdvantageImageUrl}
                alt={'fidelity_advantage'}
            />
            {formatDecimal(fidelityAdvantage)}
        </>
    );
}
